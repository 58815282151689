<template>
  <section class="section-py-60 pb-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>A teljes folyamat gyorsasága<br> <small class="fs-18">(ajánlatkéréstől magvalósulásig)</small></i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01" name="customRadio" class="rating-01" value="1" v-model="store.data.step07.q1">
              <label class="" for="QR01"><span>Nagyon elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02" name="customRadio" class="rating-02" value="2" v-model="store.data.step07.q1">
              <label class="" for="QR02"><span>Elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03" name="customRadio" class="rating-03" value="3" v-model="store.data.step07.q1">
              <label class="" for="QR03"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04" name="customRadio" class="rating-04" value="4" v-model="store.data.step07.q1">
              <label class="" for="QR04"><span>Elégedett</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05" name="customRadio" class="rating-05" value="5" v-model="store.data.step07.q1">
              <label class="" for="QR05"><span>Nagyon elégedett</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>

  <hr class="my-15">

  <section class="py-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>Kommunikáció az Optimum Solarral</i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01-A" name="customRadio-A" class="rating-01" value="1" v-model="store.data.step07.q2">
              <label class="" for="QR01-A"><span>Nagyon elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02-A" name="customRadio-A" class="rating-02" value="2" v-model="store.data.step07.q2">
              <label class="" for="QR02-A"><span>Elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03-A" name="customRadio-A" class="rating-03" value="3" v-model="store.data.step07.q2">
              <label class="" for="QR03-A"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04-A" name="customRadio-A" class="rating-04" value="4" v-model="store.data.step07.q2">
              <label class="" for="QR04-A"><span>Elégedett</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05-A" name="customRadio-A" class="rating-05" value="5" v-model="store.data.step07.q2">
              <label class="" for="QR05-A"><span>Nagyon elégedett</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>

  <hr class="my-15">

  <section class="py-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>A beépített anyagok minősége</i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01-B" name="customRadio-B" class="rating-01" value="1" v-model="store.data.step07.q3">
              <label class="" for="QR01-B"><span>Nagyon elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02-B" name="customRadio-B" class="rating-02" value="2" v-model="store.data.step07.q3">
              <label class="" for="QR02-B"><span>Elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03-B" name="customRadio-B" class="rating-03" value="3" v-model="store.data.step07.q3">
              <label class="" for="QR03-B"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04-B" name="customRadio-B" class="rating-04" value="4" v-model="store.data.step07.q3">
              <label class="" for="QR04-B"><span>Elégedett</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05-B" name="customRadio-B" class="rating-05" value="5" v-model="store.data.step07.q3">
              <label class="" for="QR05-B"><span>Nagyon elégedett</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>

  <hr class="my-15">

  <section class="py-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>A cég megbízhatósága</i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01-C" name="customRadio-C" class="rating-01" value="1" v-model="store.data.step07.q4">
              <label class="" for="QR01-C"><span>Nagyon elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02-C" name="customRadio-C" class="rating-02" value="2" v-model="store.data.step07.q4">
              <label class="" for="QR02-C"><span>Elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03-C" name="customRadio-C" class="rating-03" value="3" v-model="store.data.step07.q4">
              <label class="" for="QR03-C"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04-C" name="customRadio-C" class="rating-04" value="4" v-model="store.data.step07.q4">
              <label class="" for="QR04-C"><span>Elégedett</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05-C" name="customRadio-C" class="rating-05" value="5" v-model="store.data.step07.q4">
              <label class="" for="QR05-C"><span>Nagyon elégedett</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>

  <hr class="my-15">

  <section class="py-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>Az eszközök és szolgáltatások ár-érték aránya</i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01-D" name="customRadio-D" class="rating-01" value="1" v-model="store.data.step07.q5">
              <label class="" for="QR01-D"><span>Nagyon elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02-D" name="customRadio-D" class="rating-02" value="2" v-model="store.data.step07.q5">
              <label class="" for="QR02-D"><span>Elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03-D" name="customRadio-D" class="rating-03" value="3" v-model="store.data.step07.q5">
              <label class="" for="QR03-D"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04-D" name="customRadio-D" class="rating-04" value="4" v-model="store.data.step07.q5">
              <label class="" for="QR04-D"><span>Elégedett</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05-D" name="customRadio-D" class="rating-05" value="5" v-model="store.data.step07.q5">
              <label class="" for="QR05-D"><span>Nagyon elégedett</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>

  <hr class="my-15">

  <section class="py-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>A szükséges információk átadása</i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01-E" name="customRadio-E" class="rating-01" value="1" v-model="store.data.step07.q6">
              <label class="" for="QR01-E"><span>Nagyon elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02-E" name="customRadio-E" class="rating-02" value="2" v-model="store.data.step07.q6">
              <label class="" for="QR02-E"><span>Elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03-E" name="customRadio-E" class="rating-03" value="3" v-model="store.data.step07.q6">
              <label class="" for="QR03-E"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04-E" name="customRadio-E" class="rating-04" value="4" v-model="store.data.step07.q6">
              <label class="" for="QR04-E"><span>Elégedett</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05-E" name="customRadio-E" class="rating-05" value="5" v-model="store.data.step07.q6">
              <label class="" for="QR05-E"><span>Nagyon elégedett</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>

  <hr class="my-15">

  <section class="py-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>A cég hozzáértése</i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01-F" name="customRadio-F" class="rating-01" value="1" v-model="store.data.step07.q7">
              <label class="" for="QR01-F"><span>Nagyon elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02-F" name="customRadio-F" class="rating-02" value="2" v-model="store.data.step07.q7">
              <label class="" for="QR02-F"><span>Elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03-F" name="customRadio-F" class="rating-03" value="3" v-model="store.data.step07.q7">
              <label class="" for="QR03-F"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04-F" name="customRadio-F" class="rating-04" value="4" v-model="store.data.step07.q7">
              <label class="" for="QR04-F"><span>Elégedett</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05-F" name="customRadio-F" class="rating-05" value="5" v-model="store.data.step07.q7">
              <label class="" for="QR05-F"><span>Nagyon elégedett</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>

  <hr class="my-15">

  <section class="pb-60 pt-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>Probléma, kérdés esetén a válaszadás gyorsasága</i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01-G" name="customRadio-G" class="rating-01" value="1" v-model="store.data.step07.q8">
              <label class="" for="QR01-G"><span>Nagyon elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02-G" name="customRadio-G" class="rating-02" value="2" v-model="store.data.step07.q8">
              <label class="" for="QR02-G"><span>Elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03-G" name="customRadio-G" class="rating-03" value="3" v-model="store.data.step07.q8">
              <label class="" for="QR03-G"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04-G" name="customRadio-G" class="rating-04" value="4" v-model="store.data.step07.q8">
              <label class="" for="QR04-G"><span>Elégedett</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05-G" name="customRadio-G" class="rating-05" value="5" v-model="store.data.step07.q8">
              <label class="" for="QR05-G"><span>Nagyon elégedett</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {watch, ref, computed } from 'vue'
import { MutationType, storeToRefs } from 'pinia'
import { useSurveyStore } from '../stores/surveystore'
const store = useSurveyStore();
</script>
