<template>
  <section class="section-py-60 pb-30">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 center-col ">
          <div class="que-container">
            <form>
              <div class="custom-control custom-radio">
                <input type="radio" id="QR01" name="customRadio" class="custom-control-input" value="1" v-model="store.data.step12.q1">
                <label class="" for="QR01">Igen, névvel
                  <span>(weboldal, reklámanyagok, Facebook kampány)</span>
                </label>

                <div class="mt-15 px-15" v-if="store.data.step12.q1 == 1">
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="1" maxlength="40" placeholder="Név megadása" v-model="store.data.step12.name" required></textarea>
                </div>
              </div>

              <div class="custom-control custom-radio">
                <input type="radio" id="QR02" name="customRadio" class="custom-control-input" value="2" v-model="store.data.step12.q1">
                <label class="" for="QR02">Igen, név nélkül
                  <span>(weboldal, reklámanyagok, Facebook kampány)</span></label>
              </div>

              <div class="custom-control custom-radio">
                <input type="radio" id="QR03" name="customRadio" class="custom-control-input" value="3" v-model="store.data.step12.q1">
                <label class="" for="QR03">Nem</label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-py-60 pt-0">
    <div class="container">
        <div class="row mb-30 policy-container">
          <div class="col-12 col-md-6 center-col">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="store.data.step12.confirmed">
              <label class="custom-control-label pl-45" for="customCheck1">
                Hozzájárulok, hogy személyes adataimat az Optimum Solar Home System Kft. kezelje az <a target="_blank" href="/pdf/Optimum-Solar-home-system-UEF-adatkezelesi-szabalyzat.pdf">adatkezelési szabályzatuknak</a> megfelelően. Az Optimum Solar Home System Kft. adatkezelési szabályzatát megismertem és azt tudomásul veszem.</label>
            </div>
          </div>
        </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {watch, ref, computed } from 'vue'
import { MutationType, storeToRefs } from 'pinia'
import { useSurveyStore } from '../stores/surveystore'
const store = useSurveyStore();
</script>

<style scoped>

</style>
