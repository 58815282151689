<template>
  <section class="section-py-60 pb-30">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 center-col ">
          <div class="que-container">
            <form>
              <div class="custom-control custom-radio">
                <input type="radio" id="QR01" name="customRadio" class="custom-control-input" value="1" v-model="store.data.step03.q1">
                <label class="" for="QR01">Kollégáinktól</label>
              </div>

              <div class="custom-control custom-radio">
                <input type="radio" id="QR02" name="customRadio" class="custom-control-input" value="2" v-model="store.data.step03.q1">
                <label class="" for="QR02">A weboldalról</label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {watch, ref, computed } from 'vue'
import { MutationType, storeToRefs } from 'pinia'
import { useSurveyStore } from '../stores/surveystore'
const store = useSurveyStore();
</script>
