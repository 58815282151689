<template>
  <section class="section-py-60">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01" name="customRadio" class="rating-01" value="1" v-model="store.data.step09.q1">
              <label class="" for="QR01"><span>Nagyon elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02" name="customRadio" class="rating-02" value="2" v-model="store.data.step09.q1">
              <label class="" for="QR02"><span>Elégedetlen</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03" name="customRadio" class="rating-03" value="3" v-model="store.data.step09.q1">
              <label class="" for="QR03"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04" name="customRadio" class="rating-04" value="4" v-model="store.data.step09.q1">
              <label class="" for="QR04"><span>Elégedett</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05" name="customRadio" class="rating-05" value="5" v-model="store.data.step09.q1">
              <label class="" for="QR05"><span>Nagyon elégedett</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {watch, ref, computed } from 'vue'
import { MutationType, storeToRefs } from 'pinia'
import { useSurveyStore } from '../stores/surveystore'
const store = useSurveyStore();
</script>
