<template>
  <section class="section-py-60 pb-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>A folyamat érthető, egyértelmű volt</i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01" name="customRadio" class="rating-01" value="1" v-model="store.data.step05.q1">
              <label class="" for="QR01"><span>Egyáltalán nem értek egyet</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02" name="customRadio" class="rating-02" value="2" v-model="store.data.step05.q1">
              <label class="" for="QR02"><span>Nem értek egyet</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03" name="customRadio" class="rating-03" value="3" v-model="store.data.step05.q1">
              <label class="" for="QR03"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04" name="customRadio" class="rating-04" value="4" v-model="store.data.step05.q1">
              <label class="" for="QR04"><span>Egyetértek</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05" name="customRadio" class="rating-05" value="5" v-model="store.data.step05.q1">
              <label class="" for="QR05"><span>Teljesen egyetértek</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>

  <hr class="my-15">

  <section class="py-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>A folyamat gyors volt</i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01-A" name="customRadio-A" class="rating-01" value="1" v-model="store.data.step05.q2">
              <label class="" for="QR01-A"><span>Egyáltalán nem értek egyet</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02-A" name="customRadio-A" class="rating-02" value="2" v-model="store.data.step05.q2">
              <label class="" for="QR02-A"><span>Nem értek egyet</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03-A" name="customRadio-A" class="rating-03" value="3" v-model="store.data.step05.q2">
              <label class="" for="QR03-A"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04-A" name="customRadio-A" class="rating-04" value="4" v-model="store.data.step05.q2">
              <label class="" for="QR04-A"><span>Egyetértek</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05-A" name="customRadio-A" class="rating-05" value="5" v-model="store.data.step05.q2">
              <label class="" for="QR05-A"><span>Teljesen egyetértek</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>

  <hr class="my-15">

  <section class="pb-60 pt-0">
    <div class="container">

      <div class="row">
        <div class="col-12 col-md-8 center-col text-center">
          <h5 class="no-transform f-400"><i>Minden úgy történt, ahogyan elvártam</i></h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01-B" name="customRadio-B" class="rating-01" value="1" v-model="store.data.step05.q3">
              <label class="" for="QR01-B"><span>Egyáltalán nem értek egyet</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR02-B" name="customRadio-B" class="rating-02" value="2" v-model="store.data.step05.q3">
              <label class="" for="QR02-B"><span>Nem értek egyet</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR03-B" name="customRadio-B" class="rating-03" value="3" v-model="store.data.step05.q3">
              <label class="" for="QR03-B"><span>Semleges</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR04-B" name="customRadio-B" class="rating-04" value="4" v-model="store.data.step05.q3">
              <label class="" for="QR04-B"><span>Egyetértek</span></label>
            </div>

            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05-B" name="customRadio-B" class="rating-05" value="5" v-model="store.data.step05.q3">
              <label class="" for="QR05-B"><span>Teljesen egyetértek</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {watch, ref, computed } from 'vue'
import { MutationType, storeToRefs } from 'pinia'
import { useSurveyStore } from '../stores/surveystore'
const store = useSurveyStore();
</script>
