<script setup lang="ts">
import { AppData } from './model_types'
import { useSurveyStore } from './stores/surveystore'
import { ref, computed, watch, nextTick } from 'vue'
import { storeToRefs } from "pinia";
import Step01 from './components/Step01.vue'
import Step02 from './components/Step02.vue'
import Step03 from './components/Step03.vue'
import Step04 from './components/Step04.vue'
import Step05 from './components/Step05.vue'
import Step06 from './components/Step06.vue'
import Step07 from './components/Step07.vue'
import Step08 from './components/Step08.vue'
import Step09 from './components/Step09.vue'
import Step10 from './components/Step10.vue'
import Step11 from './components/Step11.vue'
import Step12 from './components/Step12.vue'

// dispatch input props into the store
const props = defineProps<{
  data: AppData
}>();

const store = useSurveyStore();
store.model = props.data;
store.data.step12.name = props.data.name;

// store.$subscribe((mutation, state) => {
//   //console.log('state change: ', mutation, state);
//   // persist the whole state to the local storage whenever it changes
//   //localStorage.setItem('survey', JSON.stringify(state));
// });

// INFO: https://github.com/vuejs/pinia/discussions/794
// const { step, model, data } = storeToRefs(store);
// console.log(step, model, data);
// watch(data, function(current, old) { console.log('changed data', current, old); }, { deep: true });
const { step, model, data, isLoading } = storeToRefs(store);

// augment button styles
watch(step, async function(current, old) {
  // 'nextTick'
  await nextTick();

  // lose focus
  const next_btn = document.querySelector("#nextbtn") as HTMLElement;
  const back_btn = document.querySelector("#backbtn") as HTMLElement;

  if (!next_btn || !back_btn)
    return;

  if (store.isCurrentStepValid)
  {
    next_btn.removeAttribute('disabled');
  }
  else
  {
    next_btn.setAttribute('disabled', 'disabled');
  }

  // call blur
  next_btn.blur();
  back_btn.blur();
});
</script>

<template>
  <template v-if="store.step === 0">
    <section class="pt-60 pb-120 bg-gray5">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-12 col-md-7 center-col">
            <h3 class="h6 no-transform f-400 text-center">Köszönjük, hogy az <b class="color-orange">Optimum Solar</b>-t választotta napelemes rendszere telepítéséhez!</h3>
          </div>

        </div>
      </div>
    </section>

    <section class="section-py-60 pt-0">
      <div class="container">
        <div class="row">
          <div class="col-9 col-md-5 center-col text-center">
            <img src="@img/nyitokep.png" class="img-fluid img-float mb-20" alt="" srcset="">
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-8 center-col text-center">
            <h2 class="h4 f-800">Ügyfél-elégedettségi felmérés</h2>
            <p class="lead">Kérjük, szánjon néhány percet az idejéből az alábbi 9+1 kérdésből álló elégedettségi kérdőív kitöltésére!</p>

            <button class="btn btn-lg btn-green mt-20" v-on:click="store.nextStep()">Kezdés</button>
          </div>
        </div>
      </div>
    </section>
  </template>
  <template v-else-if="store.step === 13">
    <section class="section-py-60 pt-0">
      <div class="container">
        <div class="row">
          <div class="col-9 col-md-5 center-col text-center">
            <img src="@img/nyitokep.png" class="img-fluid mt-40 mb-20" alt="" srcset="">
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-7 center-col text-center">
            <h2 class="f-800 color-orange no-transform mb-0">Köszönjük,</h2>
            <p class="lead">hogy kitöltötte elégedettségi kérdőívünket, mellyel segítette további fejlődésünket.</p>

            <p class="">Bízunk benne, hogy Ön is a legjobb elégedettséggel használja az Optimum Solar által telepített napelemes rendszert.</p>
            <p class="lh-12 mt-50"><i>Optimum Solar Home System<br> csapata</i></p>
          </div>
        </div>
      </div>
    </section>
  </template>
  <template v-else>
    <section class="section-py-30 bg-gray5" id="stepheader">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-12 col-md-10 center-col step-header">
            <h3 v-if="store.step === 1" class="no-transform mt-20 mb-0">Hol találkozott az<br> <b>Optimum Solar<span>ral?</span></b></h3>
            <h3 v-else-if="store.step === 2" class="no-transform mt-20 mb-0">Mikor kért először ajánlatot az<br> <b>Optimum Solar<span>tól?</span></b></h3>
            <h3 v-else-if="store.step === 3" class="no-transform mt-20 mb-0">Honnan kért ajánlatot először?</h3>
            <h3 v-else-if="store.step === 4" class="no-transform mt-20 mb-0">Miért az Optimum Solar-t választotta?</h3>
            <h3 v-else-if="store.step === 5" class="no-transform mt-20 mb-0">Mennyire volt elégedett az <b>ajánlatadás menetével?</b></h3>
            <h3 v-else-if="store.step === 6" class="no-transform mt-20 mb-0">Mennyire volt elégedett az <b>engedélyeztetés folyamatával?</b></h3>
            <h3 v-else-if="store.step === 7" class="no-transform mt-20 mb-0">Mennyire volt elégedett <b>a kivitelezéssel?</b></h3>
            <h3 v-else-if="store.step === 8" class="no-transform mt-20 mb-0">Értékelje <b>Munkatársunkat</b> a következők alapján!</h3>
            <h3 v-else-if="store.step === 9" class="no-transform mt-20 mb-0">Összességében mennyire elégedett a megvalósítással?</h3>
            <h3 v-else-if="store.step === 10" class="no-transform mt-20 mb-0">Ajánlaná ismerősének az<br class="visible-md"> <b>Optimum Solar-t</b>?</h3>
            <h3 v-else-if="store.step === 11" class="no-transform mt-20 mb-0">Kérjük, foglalja össze néhány gondolatban, hogy miként vélekedik az <b>Optimum Solarról?</b></h3>
            <h3 v-else-if="store.step === 12" class="no-transform mt-20 mb-0">Hozzájárul ahhoz, hogy az Ön rendszerét <b>referenciaként feltüntessük?</b></h3>
          </div>
        </div>
      </div>
    </section>

    <section class="">
      <div class="container-fluid">
        <div class="row unrow-30">
          <div class="col-12">
            <div class="progress">
              <div class="progress-bar" v-bind:style="{'width': store.progressPercent+'%'}" v-bind:aria-valuenow="store.progressPercent" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-value">{{ store.progressPercent }}<span>%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Step01 v-if="store.step === 1" />
    <Step02 v-else-if="store.step === 2" />
    <Step03 v-else-if="store.step === 3" />
    <Step04 v-else-if="store.step === 4" />
    <Step05 v-else-if="store.step === 5" />
    <Step06 v-else-if="store.step === 6" />
    <Step07 v-else-if="store.step === 7" />
    <Step08 v-else-if="store.step === 8" />
    <Step09 v-else-if="store.step === 9" />
    <Step10 v-else-if="store.step === 10" />
    <Step11 v-else-if="store.step === 11" />
    <Step12 v-else-if="store.step === 12" />

    <section class="section-py-60 pt-0">
      <div class="container">
        <div class="row justify-content-center" v-if="store.hasError">
          <div class="col-8 text-center alert alert-danger">
            {{ store.errorMsg }}
          </div>
        </div>
        <div class="row">
            <div class="col-4 col-md-3 offset-md-2 text-left" v-show="store.step > 1">
              <button role="button" id="backbtn" v-on:click="store.prevStep()" class=" btn btn-lg btn-cube line-gray30"><i class="fas fa-chevron-left"></i></button>
            </div>
            <div v-bind:class="['col-8', {'col-md-5': store.step > 1, 'col-md-10': store.step === 1}, 'text-right']">
              <button role="button" id="nextbtn" v-bind:class="['btn', 'btn-lg', {'btn-orange': store.step !== 12, 'btn-green': store.step === 12}]" v-bind:disabled="!store.isCurrentStepValid" v-on:click.prevent="store.nextStep();">
                <img src="./assets/img/loader.gif" alt="Loader" v-if="store.isLoading" />
                {{ store.nextBtnTxt }}
              </button>
            </div>
        </div>
      </div>
    </section>
  </template>
</template>

<style scoped>
.progress-bar {
  overflow: visible !important;
}
</style>
