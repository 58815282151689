<template>
  <section class="section-py-60 pb-30">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3 center-col my-30">
          <div class="que-container-line d-flex">
            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR01" name="customRadio" class="rating-05" value="1" v-model="store.data.step10.q1">
              <label class="" for="QR01"><span>IGEN</span></label>
            </div>


            <div class="custom-control custom-radio flex-fill">
              <input type="radio" id="QR05" name="customRadio" class="rating-01" value="2" v-model="store.data.step10.q1">
              <label class="" for="QR05"><span>NEM</span></label>
            </div>
          </div>

          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {watch, ref, computed } from 'vue'
import { MutationType, storeToRefs } from 'pinia'
import { useSurveyStore } from '../stores/surveystore'
const store = useSurveyStore();
</script>
