<template>
  <section class="section-py-60 pb-30">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 center-col ">
          <div class="que-container">
            <form>
              <div class="custom-control custom-radio">
                <input type="radio" id="QR01" name="customRadio" class="custom-control-input" value="1" v-model="store.data.step01.q1">
                <label class="" for="QR01">Ismerősöm ajánlotta</label>
              </div>

              <div class="custom-control custom-radio">
                <input type="radio" id="QR02" name="customRadio" class="custom-control-input" value="2" v-model="store.data.step01.q1">
                <label class="" for="QR02">Online hirdetésen keresztül</label>
              </div>

              <div class="custom-control custom-radio">
                <input type="radio" id="QR03" name="customRadio" class="custom-control-input" value="3" v-model="store.data.step01.q1">
                <label class="" for="QR03">Az interneten kerestem napelemes céget</label>
              </div>

              <div class="custom-control custom-radio">
                <input type="radio" id="QR04" name="customRadio" class="custom-control-input" value="4" v-model="store.data.step01.q1">
                <label class="" for="QR04">A közösségi médiában <span>(Facebook, Instagram, LinkedIn)</span></label>
              </div>

              <div class="custom-control custom-radio wasd">
                <input type="radio" id="QR05" name="customRadio" class="custom-control-input" value="5" v-model="store.data.step01.q1">
                <label class="" for="QR05">
                  Egyéb <span>(szabadszöveges válasz)</span>
                </label>
                <div class="mt-15 px-15" v-if="isCustom">
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" maxlength="100" placeholder="adja meg a leírást..." v-model="store.data.step01.q1_custom" required></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {watch, ref, computed } from 'vue'
import { MutationType, storeToRefs } from 'pinia'
import { useSurveyStore } from '../stores/surveystore'
const store = useSurveyStore();
const isCustom = computed(() => store.data.step01.q1 == 5);
</script>
