export function deep_iter(obj: object, mapper: (baseKey: string[], level: number, key: string, value: any) => void)
{
    deep_iter_rec(obj, 0, [], mapper);
}

export function deep_iter_rec(obj: object, level: number = 0, baseKey: string[], mapper: (baseKey: string[], level: number, key: string, value: any) => void)
{
    Object.keys(obj).forEach(function (k)
    {
        if (obj[k] !== null && typeof obj[k] === 'object') {
            deep_iter_rec(obj[k], level + 1, baseKey.concat([k]), mapper);
            return;
        }
        mapper(baseKey, level, k, obj[k]);
    });
}
