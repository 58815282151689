import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import { AppData } from './model_types'

export interface PageApp {
    init(...args: any[]) : void;
}

export const AppPage: PageApp = {
    init: (model: AppData): void => {
        createApp(App, {data: model}).use(createPinia()).mount('#app');
    }
}
