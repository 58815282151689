import { defineStore } from 'pinia'
import { AppData } from '@/model_types'
import axios, { AxiosStatic } from 'axios'
import { deep_iter } from '@/helpers'

export type SurveyDataState = {
    step: number,
    model: AppData,
    data: {
        step01: {
            q1: number,
            q1_custom: string
        },
        step02: {
            q1: number
        },
        step03: {
            q1: number
        },
        step04: {
            q1: number
        },
        step05: {
            q1: number,
            q2: number,
            q3: number
        },
        step06: {
            q1: number,
            q2: number,
            q3: number
        },
        step07: {
            q1: number,
            q2: number,
            q3: number,
            q4: number,
            q5: number,
            q6: number,
            q7: number,
            q8: number
        },
        step08: {
            q1: number,
            q2: number,
            q3: number,
            q4: number,
            q5: number,
            qcustom: string
        },
        step09: {
            q1: number
        },
        step10: {
            q1: number
        },
        step11: {
            answer: string
        },
        step12: {
            q1: number,
            name: string,
            confirmed: boolean
        }
    },
    // [key: string]: object
    isLoading: boolean,
    errorMsg: string
};

// @ts-ignore
export const useSurveyStore = defineStore({
    id: 'survey',
    state: () => ({
        step: 0,
        model: {},
        data: {
            step01: {
                q1: undefined,
                q1_custom: ''
            },
            step02: {
                q1: undefined
            },
            step03: {
                q1: undefined
            },
            step04: {
                q1: undefined
            },
            step05: {
                q1: undefined,
                q2: undefined,
                q3: undefined
            },
            step06: {
                q1: undefined,
                q2: undefined,
                q3: undefined
            },
            step07: {
                q1: undefined,
                q2: undefined,
                q3: undefined,
                q4: undefined,
                q5: undefined,
                q6: undefined,
                q7: undefined,
                q8: undefined,
            },
            step08: {
                q1: undefined,
                q2: undefined,
                q3: undefined,
                q4: undefined,
                q5: undefined,
                qcustom: ''
            },
            step09: {
                q1: undefined
            },
            step10: {
                q1: undefined
            },
            step11: {
                answer: ''
            },
            step12: {
                q1: undefined,
                name: '',
                confirmed: false
            }
        },
        isLoading: false,
        errorMsg: ''
    } as SurveyDataState),
    getters: {
        hasError: (state) => state.errorMsg?.length > 0,
        // global values
        progressPercent: (state) => {
            switch (true) {
                case (state.step == 9):
                    return 85;
                case (state.step == 10):
                    return 90;
                case (state.step == 11):
                    return 95;
                case (state.step == 12):
                    return 100;
            }

            return state.step * 10;
        },
        nextBtnTxt: (state) => {
            if (state.isLoading)
                return 'Kérjük várjon...';

            return state.step === 12 ? 'Befejezés' : 'Következő';
        },

        // validations
        isCurrentStepValid(): Boolean {
            return this.isStepValid(this.step);
        },
        isStepValid: (state) => (step: number) => {
            // always disable buttons while loading
            if (state.isLoading)
                return false;

            switch (step)
            {
                case 0:
                    return true;
                case 1:
                    return Boolean(state.data.step01.q1) && (state.data.step01.q1 != 5 || state.data.step01.q1_custom.length > 0);
                case 2:
                    return Boolean(state.data.step02.q1);
                case 3:
                    return Boolean(state.data.step03.q1);
                case 4:
                    return Boolean(state.data.step04.q1);
                case 5:
                    return Boolean(state.data.step05.q1) && Boolean(state.data.step05.q2) && Boolean(state.data.step05.q3);
                case 6:
                    return Boolean(state.data.step06.q1) && Boolean(state.data.step06.q2) && Boolean(state.data.step06.q3);
                case 7:
                    return Boolean(state.data.step07.q1) && Boolean(state.data.step07.q2) && Boolean(state.data.step07.q3) &&
                        Boolean(state.data.step07.q4) && Boolean(state.data.step07.q5) && Boolean(state.data.step07.q6) &&
                        Boolean(state.data.step07.q7) && Boolean(state.data.step07.q8);
                case 8:
                    return (Boolean(state.data.step08.q1) && Boolean(state.data.step08.q2) && Boolean(state.data.step08.q3) &&
                        Boolean(state.data.step08.q4) && Boolean(state.data.step08.q5));
                case 9:
                    return Boolean(state.data.step09.q1);
                case 10:
                    return Boolean(state.data.step10.q1);
                case 11:
                    return state.data.step11.answer.length > 0;
                case 12:
                    return Boolean(state.data.step12.q1) && (state.data.step12.q1 != 1 || state.data.step12.name.length > 0) && state.data.step12.confirmed;
            }

            return false;
        },
        // step datas
        // STEP01

        // ...
    },
    actions: {
        prevStep() {
            if (this.step > 1 && !this.isLoading) {
                this.step--;
            }
        },
        async nextStep() {
            // if (this.step == 0)
            // {
            //     //TODO: add persistence by giving ?started=1 and preloading data from store
            //     //history.pushState({key: 641.928}, '', window.location.href + '&started=');
            // }
            // only increment if data is valid
            if (this.isStepValid(this.step) && !this.isLoading) {
                // next is final step, publish data
                if (this.step == 12) {
                    this.isLoading = true;

                    let formData = new FormData();
                    formData.append(this.model.csrf_param, this.model.csrf_token);

                    deep_iter(this.data, (b, l, k, v) => {
                        const pk = b.map((val, index) => '[' + val + ']');
                        formData.append(this.model.form_prefix + pk + '[' + k + ']', v);
                    });

                    try {
                        const response = await axios.post(this.model.publishUrl, formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        });

                        if (response.data.error)
                        {
                            this.errorMsg = response.data.message;
                        }
                        else
                        {
                            this.errorMsg = ''; // reset prev. errors
                        }
                        /*await new Promise((resolve, reject) =>
                            setTimeout(() => {
                                reject('Hiba történt az adatok beküldése során. Kérjük, próbálja újra!');
                                resolve(1)
                            }, 5000)
                        );*/
                    }
                    catch (e) {
                        this.errorMsg = 'Hiba történt az adatok beküldése során. Kérjük, próbálja újra!';
                        console.log(e);
                    }
                    finally {
                        this.isLoading = false;
                    }
                }
                else if (this.hasError) { // reset error state
                    this.errorMsg = '';
                }

                // move step
                if (!this.hasError) {
                    this.step++;
                    window.scrollTo(0,0); // always show top part of the page
                }
            }

            return new Promise((resolve) => {
                resolve(0);
            });
        }
    },
})
